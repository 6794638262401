
// CreateProduct.js
import React, { useState, useEffect } from 'react';
import { createProduct, getAllByCategory, getAllSubCategory } from '../../Constant/Api/Api';
import { useDisclosure } from '@mantine/hooks';
import { Modal, TextInput, Button, Select, TagsInput, Progress, NumberInput } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import uploadFile from '../../firebase/firebaseUploadFunction';
import '@mantine/tiptap/styles.css';
import '@mantine/core/styles.css';


const generateSlug = (name) => {
    return name
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)+/g, '');
};

function CreateProduct({ fetchProductData }) {
    const [opened, { open, close }] = useDisclosure(false);
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productImages, setProductImages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [imageUploadingLoader, setImageUploadingLoader] = useState(false);
    const [sellingPrice, setSellingPrice] = useState('');
    const [mrp, setMrp] = useState('');
    const [specifications, setSpecifications] = useState([{ key: '', value: [] }]);
    const [slug, setSlug] = useState('');

    const editor = useEditor({
        extensions: [
            StarterKit,
            Link,
        ],
        content: '',
        onUpdate: ({ editor }) => {
            setProductDescription(editor.getHTML());
        }
    });

    useEffect(() => {
        fetchCategories();
        fetchSubCategories();
    }, []);

    useEffect(() => {
        setSlug(generateSlug(productName));
    }, [productName]);

    const fetchCategories = async () => {
        try {
            const response = await fetch(getAllByCategory);
            if (response.ok) {
                const data = await response.json();
                setCategories(data);
                setSelectedCategory(data.length > 0 ? data[0]._id : '');
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchSubCategories = async () => {
        try {
            const response = await fetch(getAllSubCategory);
            if (response.ok) {
                const data = await response.json();
                setSubCategories(data);
                setSelectedSubCategory(data.length > 0 ? data[0]._id : '');
            }
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };

    const handleProductImagesChange = (e) => {
        setImageUploadingLoader(true);
        uploadFile(e)
            .then((fireBaseUrl) => {
                setImageUploadingLoader(false);
                setProductImages([...productImages, fireBaseUrl]);
            })
            .catch((error) => {
                setImageUploadingLoader(false);
                console.error('Error uploading file:', error);
            });
    };

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
    };

    const handleSubCategoryChange = (value) => {
        setSelectedSubCategory(value);
    };

    const handleSpecificationChange = (index, event) => {
        const newSpecifications = specifications.map((spec, specIndex) => {
            if (index === specIndex) {
                return { ...spec, [event.target.name]: event.target.value };
            }
            return spec;
        });
        setSpecifications(newSpecifications);
    };

    const handleSpecificationTagsChange = (index, value) => {
        const newSpecifications = specifications.map((spec, specIndex) => {
            if (index === specIndex) {
                return { ...spec, value: Array.isArray(value) ? value : [value] };
            }
            return spec;
        });
        setSpecifications(newSpecifications);
    };

    const addSpecification = () => {
        setSpecifications([...specifications, { key: '', value: [] }]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const formData = {
                name: productName,
                description: productDescription,
                images: productImages,
                category: selectedCategory,
                subCategory: selectedSubCategory,
                tags,
                sellingPrice,
                mrp,
                specifications,
                slug
            };

            const response = await fetch(createProduct, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (data) {
                setProductName('');
                editor.commands.setContent('');
                setProductImages([]);
                setSelectedCategory('');
                setSelectedSubCategory('');
                setTags([]);
                setSellingPrice('');
                setMrp('');
                setSpecifications([{ key: '', value: [] }]);
                fetchProductData();
                close();
            }
        } catch (error) {
            console.error('Error creating product:', error);
        }
    };

    return (
        <div>
            <Button onClick={open}>Create Product</Button>
            <Modal opened={opened} onClose={close} title="Create Product" size="xl">
                <form onSubmit={handleSubmit}>
                    {imageUploadingLoader ? (
                        <>
                            <span className="bg-red-50">Please wait while the image is uploading... </span>
                            <Progress radius="xl" value={100} striped animated />
                        </>
                    ) : (
                        <>
                            {productImages.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`Product Image ${index}`}
                                    className="w-full h-36 object-cover"
                                />
                            ))}
                            <TextInput
                                label="Product Name"
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                                required
                            />
                            <div className="mb-4">
                                <h3>Description</h3>
                                <RichTextEditor editor={editor} styles={{ root: { minHeight: 300 } }}>
                                    <RichTextEditor.Toolbar sticky stickyOffset={0}>
                                        <RichTextEditor.ControlsGroup>
                                            <RichTextEditor.Bold />
                                            <RichTextEditor.Italic />
                                            <RichTextEditor.Underline />
                                            <RichTextEditor.Strikethrough />
                                            <RichTextEditor.ClearFormatting />
                                            <RichTextEditor.Highlight />
                                            <RichTextEditor.Code />
                                        </RichTextEditor.ControlsGroup>

                                        <RichTextEditor.ControlsGroup>
                                            <RichTextEditor.H1 />
                                            <RichTextEditor.H2 />
                                            <RichTextEditor.H3 />
                                            <RichTextEditor.H4 />
                                        </RichTextEditor.ControlsGroup>

                                        <RichTextEditor.ControlsGroup>
                                            <RichTextEditor.Blockquote />
                                            <RichTextEditor.Hr />
                                            <RichTextEditor.BulletList />
                                            <RichTextEditor.OrderedList />
                                            <RichTextEditor.Subscript />
                                            <RichTextEditor.Superscript />
                                        </RichTextEditor.ControlsGroup>

                                        <RichTextEditor.ControlsGroup>
                                            <RichTextEditor.Link />
                                            <RichTextEditor.Unlink />
                                        </RichTextEditor.ControlsGroup>

                                        <RichTextEditor.ControlsGroup>
                                            <RichTextEditor.AlignLeft />
                                            <RichTextEditor.AlignCenter />
                                            <RichTextEditor.AlignJustify />
                                            <RichTextEditor.AlignRight />
                                        </RichTextEditor.ControlsGroup>

                                        <RichTextEditor.ColorPicker
                                            colors={[
                                                '#25262b', '#868e96', '#fa5252', '#e64980',
                                                '#be4bdb', '#7950f2', '#4c6ef5', '#228be6',
                                                '#15aabf', '#12b886', '#40c057', '#82c91e',
                                                '#fab005', '#fd7e14',
                                            ]}
                                        />
                                    </RichTextEditor.Toolbar>

                                    <RichTextEditor.Content />
                                </RichTextEditor>
                            </div>
                            <TextInput label="Slug" value={slug} readOnly />
                            <Select
                                label="Category"
                                data={categories.map((category) => ({
                                    label: category.name,
                                    value: category._id,
                                }))}
                                value={selectedCategory}
                                onChange={(value) => handleCategoryChange(value)}
                                searchable
                            />
                            <Select
                                label="Subcategory"
                                data={subCategories.map((subcategory) => ({
                                    label: subcategory.name,
                                    value: subcategory._id,
                                }))}
                                value={selectedSubCategory}
                                onChange={(value) => handleSubCategoryChange(value)}
                                searchable
                            />
                            <TagsInput
                                label="Press Enter to submit a tag"
                                placeholder="Enter tag"
                                value={tags}
                                onChange={setTags}
                            />
                            <NumberInput
                                label="Selling Price"
                                value={sellingPrice}
                                onChange={setSellingPrice}
                            />
                            <NumberInput label="MRP" value={mrp} onChange={setMrp} />
                            <div>
                                <label>Specifications</label>
                                {specifications.map((spec, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <TextInput
                                            placeholder="Key"
                                            name="key"
                                            value={spec.key}
                                            onChange={(event) =>
                                                handleSpecificationChange(index, event)
                                            }
                                            required
                                        />
                                        <TagsInput
                                            placeholder="Enter value"
                                            value={Array.isArray(spec.value) ? spec.value : [spec.value]}
                                            onChange={(value) =>
                                                handleSpecificationTagsChange(index, value)
                                            }
                                        />
                                    </div>
                                ))}
                                <Button type="button" onClick={addSpecification}>
                                    Add Specification
                                </Button>
                            </div>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleProductImagesChange(e)}
                                multiple
                            />
                            <Button type="submit">Create</Button>
                        </>
                    )}
                </form>
            </Modal>
        </div>)
}

export default CreateProduct;